import { cva } from 'class-variance-authority';

export const stickerCVA = cva('rounded-dt-radius-small flex h-fit w-fit', {
  variants: {
    variant: {
      live: undefined,
      date: undefined,
      subscribe: undefined,
      get: undefined,
      'offered-content': undefined,
      'free-to-air': undefined,
      order: undefined,
      'last-days': undefined,
    },
    isTv: {
      true: 'px-6',
      false: 'px-4',
    },
    iconPosition: {
      right: 'flex-row-reverse',
      left: undefined,
    },
  },
  compoundVariants: [
    {
      isTv: false,
      variant: 'live',
      className: 'bg-dt-theme-surface-sticker-sticker-live',
    },
    {
      isTv: true,
      variant: 'live',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-live',
    },
    {
      isTv: false,
      variant: 'date',
      className: 'bg-dt-theme-surface-sticker-sticker-date',
    },
    {
      isTv: true,
      variant: 'date',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-date',
    },
    {
      isTv: false,
      variant: 'subscribe',
      className: 'bg-dt-theme-surface-sticker-sticker-subscribe',
    },
    {
      isTv: true,
      variant: 'subscribe',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-subscribe',
    },
    {
      isTv: false,
      variant: 'get',
      className: 'bg-dt-theme-surface-sticker-sticker-get',
    },
    {
      isTv: true,
      variant: 'get',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-get',
    },
    {
      isTv: false,
      variant: 'offered-content',
      className: 'bg-dt-theme-surface-sticker-sticker-offered-content',
    },
    {
      isTv: true,
      variant: 'offered-content',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-offered-content',
    },
    {
      isTv: false,
      variant: 'free-to-air',
      className: 'bg-dt-theme-surface-sticker-sticker-free-to-air',
    },
    {
      isTv: true,
      variant: 'free-to-air',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-free-to-air',
    },
    {
      isTv: false,
      variant: 'order',
      className: 'bg-dt-theme-surface-sticker-sticker-order',
    },
    {
      isTv: true,
      variant: 'order',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-order',
    },
    {
      isTv: false,
      variant: 'last-days',
      className: 'bg-dt-theme-surface-sticker-sticker-last-days',
    },
    {
      isTv: true,
      variant: 'last-days',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-last-days',
    },
  ],
});

export const stickerIconCVA = cva('', {
  variants: {
    isTv: {
      true: 'h-16',
      false: 'h-[0.6rem]',
    },
    variant: {
      live: undefined,
      date: undefined,
      subscribe: undefined,
      get: undefined,
      'offered-content': undefined,
      'free-to-air': undefined,
      order: undefined,
      'last-days': undefined,
    },
  },
  compoundVariants: [
    {
      isTv: true,
      variant: ['live', 'date', 'subscribe', 'get', 'offered-content', 'free-to-air', 'order', 'last-days'],
      className: 'fill-dt-theme-tv-icon-sticker-icon',
    },
    {
      isTv: false,
      variant: ['live', 'date', 'subscribe', 'get', 'offered-content', 'free-to-air', 'order', 'last-days'],
      className: 'fill-dt-theme-icon-sticker-icon',
    },
  ],
});

export const stickerLabelCVA = cva(
  [
    'text-12 text-ellipsis overflow-hidden whitespace-nowrap pb-0',
    'm-0', // ! temporary fallback for missing `@tailwind base`
  ],
  {
    variants: {
      isTv: {
        true: 'text-22 py-3',
        false: 'text-12 py-2',
      },
      variant: {
        live: 'font-hind font-semibold',
        date: 'font-hind',
        subscribe: 'font-hind font-semibold',
        get: 'font-hind font-semibold',
        'offered-content': 'font-hind font-semibold',
        'free-to-air': 'font-hind',
        order: 'font-hind font-semibold',
        'last-days': 'font-hind font-semibold',
      },
      iconPosition: {
        right: 'flex-row-reverse',
        left: undefined,
      },
      hasIcon: {
        true: undefined,
        false: undefined,
      },
    },
    compoundVariants: [
      {
        hasIcon: true,
        iconPosition: 'left',
        className: 'ml-4',
      },
      {
        hasIcon: true,
        iconPosition: 'right',
        className: 'mr-4',
      },
      {
        isTv: false,
        variant: 'live',
        className: 'text-dt-theme-text-sticker-sticker-live',
      },
      {
        isTv: true,
        variant: 'live',
        className: 'text-dt-theme-tv-text-sticker-sticker-live',
      },
      {
        isTv: false,
        variant: 'date',
        className: 'text-dt-theme-text-sticker-sticker-date',
      },
      {
        isTv: true,
        variant: 'date',
        className: 'text-dt-theme-tv-text-sticker-sticker-date',
      },
      {
        isTv: false,
        variant: 'subscribe',
        className: 'text-dt-theme-text-sticker-sticker-subscribe',
      },
      {
        isTv: true,
        variant: 'subscribe',
        className: 'text-dt-theme-tv-text-sticker-sticker-subscribe',
      },
      {
        isTv: false,
        variant: 'get',
        className: 'text-dt-theme-text-sticker-sticker-get',
      },
      {
        isTv: true,
        variant: 'get',
        className: 'text-dt-theme-tv-text-sticker-sticker-get',
      },
      {
        isTv: false,
        variant: 'offered-content',
        className: 'text-dt-theme-text-sticker-sticker-offered-content',
      },
      {
        isTv: true,
        variant: 'offered-content',
        className: 'text-dt-theme-tv-text-sticker-sticker-offered-content',
      },
      {
        isTv: false,
        variant: 'free-to-air',
        className: 'text-dt-theme-text-sticker-sticker-free-to-air',
      },
      {
        isTv: true,
        variant: 'free-to-air',
        className: 'text-dt-theme-tv-text-sticker-sticker-free-to-air',
      },
      {
        isTv: false,
        variant: 'order',
        className: 'text-dt-theme-text-sticker-sticker-order',
      },
      {
        isTv: true,
        variant: 'order',
        className: 'text-dt-theme-tv-text-sticker-sticker-order',
      },
      {
        isTv: false,
        variant: 'last-days',
        className: 'text-dt-theme-text-sticker-sticker-last-days',
      },
      {
        isTv: true,
        variant: 'last-days',
        className: 'text-dt-theme-tv-text-sticker-sticker-last-days',
      },
    ],
  }
);
