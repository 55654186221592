import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Label from '../../../components/Label/Label';
import I18n from '../../../lang';
import styles from './AvailabilityLabel.css';

/**
 * AvailabilityLabel
 *
 * Label added to cards to show the availability of a content (can be price, duration, ...)
 *
 * @param {bool}     [isAlreadyReadable]              true if the user is able to read the content
 * @param {bool}     [availabilityEndDate]            Date at which the content will no longer be available
 * @param {bool}     [availabilityEndDateLabel]       Label at which the content will no longer be available
 * @param {bool}     [minimumPriceInfo]               Object giving the price informations of the content
 * @param {bool}     [minimumPriceInfo.promotion]     True if the minimumPrice is a promotion
 * @param {number}   [minimumPriceInfo.minimumPrice]  Minimum price to buy the content
 *
 */
// eslint-disable-next-line react/prefer-stateless-function
class AvailabilityLabel extends PureComponent {
  render() {
    const {
      isAlreadyReadable = false,
      availabilityEndDate,
      availabilityEndDateLabel,
      minimumPriceInfo,
      t,
    } = this.props;

    if (isAlreadyReadable && (availabilityEndDateLabel || availabilityEndDate)) {
      return (
        <Label size="small" color="tvod" rounded e2e="availabilityLabel__endDate">
          {availabilityEndDateLabel || `${t('AvailabilityLabel.until')} ${availabilityEndDate}`}
        </Label>
      );
    }

    if (!isAlreadyReadable && minimumPriceInfo) {
      const promoLabel = minimumPriceInfo.promotion && (
        <span className={styles.availabilityLabel__promo} data-e2e="availabilityLabel__promo">
          {t('AvailabilityLabel.promotion')}
        </span>
      );

      const priceLabel = minimumPriceInfo.minimumPrice.toLocaleString({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const minPriceLabel =
        minimumPriceInfo.minimumPrice === 0 ? (
          <span data-e2e="availabilityLabel__offered">{t('AvailabilityLabel.free')}</span>
        ) : (
          <span data-e2e="availabilityLabel__price">
            {t('AvailabilityLabel.minimumPrice')} {priceLabel}€
          </span>
        );

      return (
        <Label size="small" color="tvod" rounded>
          {promoLabel}
          {minPriceLabel}
        </Label>
      );
    }

    return null;
  }
}

AvailabilityLabel.propTypes = {
  isAlreadyReadable: PropTypes.bool,
  availabilityEndDate: PropTypes.string,
  availabilityEndDateLabel: PropTypes.string,
  minimumPriceInfo: PropTypes.shape({
    promotion: PropTypes.bool,
    minimumPrice: PropTypes.number,
  }),
  t: PropTypes.func.isRequired,
};

AvailabilityLabel.defaultProps = {
  isAlreadyReadable: false,
  minimumPriceInfo: null,
  availabilityEndDate: null,
  availabilityEndDateLabel: null,
};

export default I18n.consumer(AvailabilityLabel);
