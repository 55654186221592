import { Sticker as DiveSticker, PlayerPlaySvg, StickerVariant } from '@canalplus/dive';
import { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import { useSelector } from 'react-redux';
import { MAP_HODOR_STICKER_TYPE_TO_STICKER_VARIANT } from '../../constants/stickers';
import { dateFormat, getCurrentTimestamp } from '../../helpers/date/date-helper';
import I18n from '../../lang';
import { langKeySelector } from '../../store/slices/application-selectors';

export const STICKER_LABEL_LIVE = 'LIVE';

type StickerProps = {
  /**
   * Data for HODOR-generated Stickers, containing the sticker `type` and `label`.
   * @see {@link https://canal-wiki.canal-plus.com/pages/viewpage.action?pageId=236094684 Hodor Sticker specs}
   */
  sticker?: ApiV2SpyroStrateContentSticker;
  /**
   * The variant type for the DIVE Sticker, defining its visual style.
   * @see {@link https://www.figma.com/design/VkDoI3oG7dgylYIUkqwXPk/%5BDIVE---Web-UI-KIT%5D---V1.2.1---15%2F12%2F2022?node-id=1016-70628&m=dev DIVE Sticker specs}
   */
  variant?: `${StickerVariant}`;
  /**
   * Timestamp (in milliseconds) for the **start** of the media associated with the sticker.
   * Used to determine when the media starts, such as for upcoming events or programs.
   */
  startTime?: number;
  /**
   * Timestamp (in milliseconds) for the **end** of the media associated with the sticker.
   * Used to determine when the media ends.
   */
  endTime?: number;
  /** Optional additional class names for custom styling of the sticker component. */
  className?: string;
};

/**
 * Component used to render DIVE Stickers based on the provided data and state.
 *
 * The component conditionally renders different types of stickers based on:
 * - The `sticker` data provided by the HODOR API
 * - The `variant` of the sticker (such as `live`, `last-days`, or `date`)
 * - Timing information (`startTime` and `endTime`) for media-related stickers.
 */
function Sticker({ className, sticker, startTime, endTime, variant }: StickerProps): JSX.Element | null {
  const langKey = useSelector(langKeySelector);
  const { t } = I18n.useTranslation();

  if (sticker && sticker.label) {
    const diveStickerVariant = MAP_HODOR_STICKER_TYPE_TO_STICKER_VARIANT[sticker.type];
    return (
      <DiveSticker
        variant={diveStickerVariant}
        label={sticker.label}
        icon={sticker.type === 'offered-content' ? <PlayerPlaySvg title={sticker.label} /> : undefined}
        className={className}
      />
    );
  }

  if (variant === 'last-days') {
    return <DiveSticker variant="last-days" label={t('Card.isLastDays')} className={className} />;
  }

  if (variant === 'live') {
    return <DiveSticker variant="live" label={STICKER_LABEL_LIVE} className={className} />;
  }

  if (startTime && endTime && getCurrentTimestamp() <= startTime) {
    const stickerDateLabel = dateFormat(t, startTime, langKey, true);
    if (stickerDateLabel) {
      return <DiveSticker variant="date" label={stickerDateLabel} className={className} />;
    }
  }

  return null;
}

export default Sticker;
