import { useSelector } from 'react-redux';
import { imageQualityPercentageSelector, maxImageRatioSelector } from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';
import Card from './Card';
import { CardProps } from './types';

export type CardConnectedProps = Omit<CardProps, 'imageQualityPercentage' | 'isTvDevice' | 'maxImageRatio'>;

export default function CardConnected(props: CardConnectedProps): JSX.Element {
  const imageQualityPercentageFromUserSettings = useSelector(imageQualityPercentageBySettingsSelector);
  const imageQualityPercentageFromAppSettings = useSelector(imageQualityPercentageSelector);
  const maxImageRatio = useSelector(maxImageRatioSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  return (
    <Card
      {...props}
      imageQualityPercentage={imageQualityPercentageFromUserSettings || imageQualityPercentageFromAppSettings}
      isTvDevice={isTvDevice}
      maxImageRatio={maxImageRatio}
    />
  );
}
